import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions/auth'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import * as serverconfig from '../serverconn'


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class NormalLoginForm extends React.Component {

  state = {
    userName:'' ,  
    password:'',
    companyprofile:{},
  }

  componentDidMount(){
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.userName !='' || this.state.password !='' ){
      this.props.onAuth(this.state.userName,this.state.password)
      this.props.history.push('/home/');
    }
  }
  
  //Methods to get username and password
  handleUserNameChange= (e) => this.setState({ userName: e.target.value});
  handlePasswordChange= (e) => this.setState({ password: e.target.value});

  render(){
    let errorMessage=null;
    if (this.props.error){
      errorMessage = (
        <p>{this.props.error.message}</p>
      );
    }

    return (
      
      <div>
        {errorMessage}

        {
          this.props.loading ?
          <Spin indicator={antIcon} />
          :

          <Card title={this.state.companyprofile.company_name===undefined?"":this.state.companyprofile.company_name+"  LOGIN"} bordered={false} style={{ width: 300 }}>
 
             <Card.Grid style={{ width: 300 }}>
              <p style={{textAlign: 'center'}}>
                {
                  this.state.companyprofile.company_logo===undefined?
                  null:
                  <Avatar size={100} icon={<UserOutlined />} alt="Company Logo" src={this.state.companyprofile.company_logo} />
                }
              </p>
              <Form
              name="normal_login"
              className="login-form">
        
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" value={this.state.userName} onChange={this.handleUserNameChange} />
              </Form.Item>
        
        
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
              </Form.Item>
        
              <Form.Item>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                <Button onClick={this.handleSubmit} type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
                </div>
              </Form.Item>
            </Form>

          </Card.Grid>
          </Card>

        }


  </div>

      );
  }
}

const WrappedNormalLoginForm= NormalLoginForm;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }

}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm));
