export const backendserverurl='https://3-be-pifs02-mta-bow.pitech.co.ug'

//https://3-be-pifs02-mta-bow.pitech.co.ug
//http://127.0.0.1:8000
//http://138.197.8.179:10226/

//kirudde
//http://1:7879
//192.168.0.11:7879

//be-medistores.pitech.co.ug
//be-medistores.pitech.co.ug

//http://192.168.100.5:7879

//http://192.168.188.5:7879


//


