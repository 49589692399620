import React from 'react';
import { Route } from 'react-router-dom';
import Login from './views/containers/Login'
import PrivacyPolicy from './views/containers/privacypolicy'

const LoginBaseRouter = () =>(
    <div>
        <Route exact path='/' component={Login}  />
        <Route exact path='/privacypolicy/' component={PrivacyPolicy} />
    </div>
);

export default LoginBaseRouter;


