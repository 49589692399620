import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'antd/dist/antd.css'; 

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/containers/LoginLayout'
import Login from './views/containers/Login';
import LoginBaseRouter from './loginroutes'


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));



class App extends Component {

  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      this.props.isAuthenticated ?
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
      :
      <Router>
      <LoginLayout>
        <LoginBaseRouter />
      </LoginLayout>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
